import React, {Component} from 'react'
import './style.css'
import {config} from "../../../Config/Config"; 


class App extends Component {
  constructor(props) {
    console.log(props);
    super(props);
    this.state = {
      item: props.data,
      showMe:false
    };
  }

toggle(){
    this.setState({
        showMe:!this.state.showMe
    })
}


//   const CardData = [
    
//     {
//      "id": "01",
//      "badge":"Low KMS",
//      "year":"2018",
//      "img":"https://imageonthefly.autodatadirect.com/images/?USER=eDealer&PW=edealer872&IMG=USC80HOC011A021001.jpg&width=440&height=262",
//      "badge1":"Used",
//      "badge2":"$28,000.00",
//      "badge3":"13000 Kms",
//      "title":"Honda Accord LX"
     
//   },

//   {
//      "id": "02",
//      "badge":"Fully-Loaded",
//      "year":"2017",
//      "img":"https://imageonthefly.autodatadirect.com/images/?USER=eDealer&PW=edealer872&IMG=CAC80HOC021B121001.jpg&width=440&height=262e",
//      "badge1":"Used",
//      "badge2":"$28,000.00",
     
//      "badge3":"13000 Kms",
//      "title":"Honda CIVIC HATCHBACK LS"
//   },
//   {
//     "id": "03",
//     "badge":"Low KMS",
//     "year":"2018",
//     "img":"https://imageonthefly.autodatadirect.com/images/?USER=eDealer&PW=edealer872&IMG=USC80HOC091A021001.jpg&width=440&height=262",
//     "badge1":"Used",
//     "badge2":"$22,000.00",
//     "badge3":"8000 Kms",
//     "title":"Honda Accord Hybrid LT"
//  }
//  ]
render(){
    return (
      
            <div>
            <div className="card9-container">
            <div className="row" id="ads">
              {/* Category Card */}
              {this.props.item.card_type.map((item) => (
              <>
              <div className="col-md-4 mt-5">
             
                  <div className="card rounded">
                  <div className="card-image">

                    <span className="card-notify-badge" >{item.heading}</span>
                    <span className="card-notify-year">{item.year}</span>
                   
                    <img className="img-fluid" src={`${config.apiurl}${item.image.url}`}  alt="Alternate Text" />
                  
                    </div>

                  <div className="card-image-overlay m-auto">
                    <span className="card-detail-badge">{item.used}</span>
                    <span className="card-detail-badge">{item.INR}</span>
                    <span className="card-detail-badge">{item.distance}</span>
                  </div>
                  <div className="card-body text-center">
                    <div className="ad-title m-auto">
                      {/* <h1>I am the data that was hidden behindI am the data that was hidden </h1>
                    {
                        this.state.showMe?
                        <div>
                          <h1>I am the data that was hidden behindI am the data that was hidden behindI am the data that was hidden behind</h1>
                          <h1 className="mt-5 text-black">I am the data that was hidden behind this button.</h1>     

                        </div>
                        
                        :null
                    } */}
                      <h5>{item.discription}</h5>
                   
                    </div>
                    <button className="btn btn-primary" onClick={()=>this.toggle()}>{item.VIEW_MORE}</button>
                    {/* <a className="ad-btn"  href="#">View</a> */}
                  </div>
                  </div>
              
            </div>
            </>
              ))}
            </div>
            </div>
            </div>
      
    )
}
}

export default App;