import React from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import tw from "twin.macro";
import { config } from "../../../Config/Config.js";
// import styled from "styled-components";
import Fade from 'react-reveal/Fade';
import PopUp from "../Modal";
import "./tabtype2.css"
import { Container, ContentWithPaddingXl } from "../../../assets/misc/Layouts.js";
import { Row,Col } from 'antd';
const ThreeColumn = tw.div`flex flex-wrap items-center lg:items-stretch lg:flex-row`;
const Column = tw.div`mt-12 lg:w-1/4`;
const Card = tw.div`lg:mx-4 xl:mx-8 max-w-sm lg:max-w-xs rounded`;
// const Image = styled.div(props => [
//   `background-image: url("${props.imageSrc}");`,
//   tw`bg-cover bg-center h-80 lg:h-64 rounded`
// ]);
const Category = tw.div`mt-4 text-gray-100 font-bold text-lg py-2`;
const Heading = tw.h1`font-black text-3xl md:text-5xl leading-snug max-w-full text-gray-100 text-center`;
const Occasion = tw.div`mt-4 text-gray-600 font-bold text-lg`;
const Title = tw.h4`mt-2 leading-relaxed font-bold text-lg text-gray-100`;

export default function Tab2({ props, item = props }) {
    return (
        <Container id="featured" className="tab-container">
        <ContentWithPaddingXl className="tab-content">
        <Fade right>
        <Tabs style={{padding:'0 5px'}} >
            <TabList className="react-tabs">
                <Row>
                    <Col md={24}>
                <Heading>Featured Collection</Heading>
                </Col>
                <Col md={24}>
                {item.Tabs.map((n) =>
                    <Tab>{n.title}</Tab>
                )}
                </Col>
                </Row>
            </TabList>
            {item.Tabs.map((n) => (
                    <TabPanel>
                        <ThreeColumn>
                        {n.TabContent.map((n,index) =>(
                                <Column key={index} className="col-md-6">
                                    <Card>
                                    <img className="tab-img" src={`${config.apiurl}${n.image.formats.small? n.image.formats.small.url: n.image.url}`} alt="img"/>
                                        <Title className="tab-title">{n.name}</Title>
                                        <Occasion className="tab-Occasion">{n.occasion}</Occasion>
                                        <Category className="tab-Category">Rs. {n.price}</Category>
                                        <PopUp/>
                                    </Card>
                                </Column>
                            ))}
                        </ThreeColumn>
                    </TabPanel>
            
            )
            )}
            
        </Tabs>
        </Fade>
        </ContentWithPaddingXl>
        </Container>
    );
}