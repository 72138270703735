import React, { useState } from "react";
import Slider from "react-slick";
import tw from "twin.macro";
import { config } from "../../../Config/Config.js";
import "./richcard.css";
import styled from "styled-components";
import { SectionHeading } from "../../../assets/misc/Headings";
import { PrimaryButton as PrimaryButtonBase } from "../../../assets/misc/Buttons";
import { ReactComponent as StarIcon } from "feather-icons/dist/icons/star.svg";
import { ReactComponent as ChevronLeftIcon } from "feather-icons/dist/icons/chevron-left.svg";
import { ReactComponent as ChevronRightIcon } from "feather-icons/dist/icons/chevron-right.svg";
import Fade from 'react-reveal/Fade';
import PopUp from "../../Common/PopUp/Modal";

const Container = tw.div`relative p-5`;
const Content = tw.div`max-w-screen-xl mx-auto py-16 lg:py-20`;

const HeadingWithControl = tw.div`flex flex-col items-center sm:items-stretch sm:flex-row justify-between`;
const Heading = tw(SectionHeading)`text-gray-100`;
const Controls = tw.div`flex items-center`;
const ControlButton = styled(PrimaryButtonBase)`
  ${tw`mt-4 sm:mt-0 first:ml-0 ml-6 rounded-full p-2`}
  svg {
    ${tw`w-6 h-6`}
  }
`;
const PrevButton = tw(ControlButton)``;
const NextButton = tw(ControlButton)``;

const CardSlider = styled(Slider)`
  ${tw`mt-16`}
  .slick-track { 
    ${tw`flex`}
  }
  .slick-slide {
    ${tw`h-auto flex justify-center mb-1`}
  }
`;
const Card = tw.div`h-full flex!  flex-col p-5  max-w-sm min-w-full rounded rounded-tl-3xl rounded-br-3xl relative focus:outline-none`;
// const CardImage = styled.div(props => [
//   `background-image: url("${props.imageSrc}");`,
//   tw`w-full h-56 sm:h-64 bg-cover bg-center   rounded sm:rounded-none sm:rounded-tl-2xl  `
// ]);

const TextInfo = tw.div`py-6 sm:px-10 sm:py-6`;
const TitleReviewContainer = tw.div`flex flex-col sm:flex-row sm:justify-between sm:items-center`;
const Title = tw.h5`text-2xl font-bold text-gray-100`;


const RatingsInfo = styled.div`
  ${tw`flex items-center sm:ml-4 mt-2 sm:mt-0`}
  svg {
    ${tw`w-6 h-6 text-yellow-500 fill-current`}
  }
`;
// const Rating = tw.span`ml-2 font-bold`;

const Description = tw.p`text-lg leading-loose mt-2 md:mt-4`;

// const SecondaryInfoContainer = tw.div`flex flex-col sm:flex-row mt-2 sm:mt-4`;
// const IconWithText = tw.div`flex items-center mr-6 my-2 sm:my-0`;
// const IconContainer = styled.div`
//   ${tw`inline-block rounded-full p-2 bg-gray-700 text-gray-100`}
//   svg {
//     ${tw`w-3 h-3`}
//   }
// `;
// const Text = tw.div`ml-2 text-sm font-semibold text-gray-800`;

// const PrimaryButton = tw(PrimaryButtonBase)`mt-auto sm:text-lg rounded-none w-full rounded sm:rounded-none sm:rounded-br-3xl py-3 sm:py-6`;
export default function RichcardType1 ({
  props,
  item = props
}) {
  console.log(item);
  // useState is used instead of useRef below because we want to re-render when sliderRef becomes available (not null)
  // const [loading, setLoading] = useState(false);
  const [sliderRef, setSliderRef] = useState(null);
  const sliderSettings = {
    arrows: false,
    slidesToShow: 3,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 2,
        }
      },

      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
        }
      },
    ]
  };



  return (
    <Container>
      <Fade right>
      <Content className="rich-container">
        <HeadingWithControl>
          <Heading>{item.title}</Heading>
          <Controls>
            <PrevButton onClick={sliderRef?.slickPrev}><ChevronLeftIcon/></PrevButton>
            <NextButton onClick={sliderRef?.slickNext}><ChevronRightIcon/></NextButton>
          </Controls>
        </HeadingWithControl>
        <CardSlider className="rich-card" ref={setSliderRef} {...sliderSettings}>
          {item.Richcard.map((card, index) => (
              
            <Card key={index}>
              <img className="rich-img" src={`${config.apiurl}${card.image.formats.small? card.image.formats.small.url: card.image.formats.thumbnail? card.image.formats.thumbnail.url: card.image.url}` } alt="img"
               />
              <TextInfo>
                <TitleReviewContainer>
                  <Title style={{overflowWrap:"anywhere"}}>{card.sub_heading}</Title>
                  <RatingsInfo>
                    <StarIcon />
                    {/* <Rating>{card.rating}</Rating> */}
                  </RatingsInfo>
                </TitleReviewContainer>
                <Description style={{overflowWrap:"break-word"}}>{card.discription}</Description>
              </TextInfo>
              <PopUp card={card}/>
            </Card>
          ))}
        </CardSlider>
      </Content>
      </Fade>
    </Container>
  );
};