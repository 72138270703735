import React from "react";
import tw from "twin.macro";
import { config } from "../../../Config/Config.js"
import "./blog.css"
// import styled from "styled-components";
import { SectionHeading as HeadingTitle } from "../../../assets/misc/Headings.js";
// import { ReactComponent as SvgDecoratorBlob1 } from "../../../assets/Images/svg-decorator-blob-1.svg";
// import { ReactComponent as SvgDecoratorBlob2 } from "../../../assets/Images/svg-decorator-blob-3.svg";
// import Fade from 'react-reveal/Fade';
import PopUp from "../Modal";


// const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;
const ThreeColumn = tw.div`flex flex-col items-center lg:items-stretch lg:flex-row flex-wrap`;
const Column = tw.div`mt-24 lg:w-1/3`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-4 font-medium text-gray-600 text-center max-w-sm`;

const Card = tw.div`lg:mx-4 xl:mx-8 max-w-sm lg:max-w-xs`;
// const Image = styled.div(props => [
//   `background-image: url("${props.imageSrc}");`,
//   tw`bg-cover bg-center h-80 lg:h-64 rounded `
// ]);
const Category = tw.div`mt-4 text-gray-100 font-bold text-sm`;
const Title = tw.h4`mt-2 leading-relaxed font-bold text-lg`;
//const Link = tw.a`inline-block mt-2 text-sm text-gray-500 font-bold cursor-pointer transition duration-300 border-b-2 border-transparent hover:border-gray-500`;

// const DecoratorBlob1 = tw(
//   SvgDecoratorBlob1
// )` absolute bottom-0 right-0 w-48 h-48 transform translate-x-40 -translate-y-8 opacity-25`;
// const DecoratorBlob2 = tw(
//   SvgDecoratorBlob2
// )` absolute top-0 left-0 w-48 h-48 transform -translate-x-32 translate-y-full opacity-25`;

export default function Cardtype5 ( {
    props,
    item = props
}) {
    console.log(item);
//   const blogPosts = [
//     {
//       imageSrc:
//         "https://images.unsplash.com/photo-1516450360452-9312f5e86fc7?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=80",
//       category: "Event Tips",
//       title: "Finding Amazing Events Near You - Fast, Cheap & Free",
//       url: "https://timerse.com"
//     },
//     {
//       imageSrc:
//         "https://images.unsplash.com/photo-1543365067-fa127bcb2303?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=80",
//       category: "Reviews",
//       title: "The Top Rated Musical Concerts Worldwide in 2019",
//       url: "https://reddit.com"
//     },
//     {
//       imageSrc:
//         "https://images.unsplash.com/photo-1499364615650-ec38552f4f34?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=80",
//       category: "Discover",
//       title: "This female band is making buzz all over the world",
//       url: "https://timerse.com"
//     }
//   ];
  return (

    
      <Content id="product" className="blog-content">
        <HeadingInfoContainer>
          <HeadingTitle>{item.title}</HeadingTitle>
          <HeadingDescription>{item.subtitle}</HeadingDescription>
        </HeadingInfoContainer>
        <ThreeColumn>
          {item.blog.map((post, index) => (
            <Column className="blog-column" key={index}>
              <Card className="blog-card">
              <img className="blog-img" src={`${config.apiurl}${post.image.formats.small? post.image.formats.small.url: post.image.url}`} alt="img"/>
                <Category>{post.subheading}</Category>
                <Title className="blog-title">{post.description}</Title>
                {/* <Link href={post.readpost}>Read Post</Link> */}
                <PopUp item={item}/>
              </Card>
            </Column>
          ))}
        </ThreeColumn>
      </Content>
    
      
   
  );
};