import React, { useState } from 'react';
import 'antd/dist/antd.css';
import { Modal, Button } from 'antd';
import { Row,Col } from 'antd';
import { config } from '../../../Config/Config';

const App = (
 card) => {
    const [visible, setVisible] = useState(false);
    console.log(card);
    return (
      <>
        <Button type="primary" className="btn-primary" onClick={() => setVisible(true)}>
          Buy Now
        </Button>
        <Modal
          title=""
          centered
          visible={visible}
          onOk={() => setVisible(false)}
          onCancel={() => setVisible(false)}
          footer={null}
          width={600}
        >
          <Row>
            <Col span={14}>
          <h1 style={{fontSize:'30px',fontWeight:'800'}}>Contact Info</h1>
          <p>{card.card.customer.name}</p>
          <p>{card.card.customer.phoneno}</p>
          <p>{card.card.customer.email}</p>
          </Col>
          <Col span={10}>
          {/* <p>{card.card.contactInfo.email}</p> */}
            
           <img src={`${config.apiurl}${card.card.image.url}` } alt=""  style={{width:'200px',height:'200px'}}/>
          
          </Col>
          </Row>
        </Modal>
      </>
    );
  };

export default App;