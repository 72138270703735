import React from "react";
import tw from "twin.macro";
import { config } from "../../../Config/Config.js";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading } from "../../../assets/misc/Headings.js";
import "../pcard.css";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
// const ImageColumn = tw(Column)`md:w-6/12 lg:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)(props => [
  tw`md:w-6/12 mt-8 md:mt-0`,
  props.textOnLeft ? tw`md:mr-8 lg:mr-16 md:order-first` : tw`md:ml-8 lg:ml-16 md:order-last`
]);

// const Image = styled.div(props => [
//   `background-image: url("${props.imageSrc}");`,
//   tw`rounded bg-cover bg-center h-full`,
// ]);
const TextContent = tw.div`lg:py-8`;

const Heading = tw(SectionHeading)`text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight text-gray-100`;
const Description = tw.p`text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-gray-100 mt-4`

const Statistics = tw.div`mt-6 lg:mt-8 xl:mt-16 flex flex-wrap`
const Statistic = tw.div`text-lg sm:text-2xl lg:text-3xl w-1/2 mt-4 lg:mt-10 text-center md:text-left`
const Value = tw.div`font-bold text-gray-500`
const Key = tw.div`font-medium text-gray-700`

export default function PreamblecardsType ({textOnLeft = false,
props,
item =props
}) {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.
  //Change the statistics variable as you like, add or delete objects
  // const statistics = [
  //   {
  //     key: "Countries",
  //     value: "192",
  //   },
  //   {
  //     key: "Hotels",
  //     value: "479",
  //   },
  //   {
  //     key: "Rooms",
  //     value: "2093",
  //   },
  //   {
  //     key: "Workers",
  //     value: "10347",
  //   }
  // ]

  return (
    <Container>
      <TwoColumn id="statistics" className="pcard-column">
        {/* <ImageColumn className="pcard-img-column"> */}
        <img className="pcard-image" src={`${config.apiurl}${item.image.formats.small? item.image.formats.small.url: item.image.url}`} alt="imgs"/>
          {/* </ImageColumn> */}
        <TextColumn className="pcard-text-column" textOnLeft={textOnLeft}>
          <TextContent className="pcard-text-container">
            <Heading>{item.title}</Heading>
            <Description className="pcard-discription">{item.discription}</Description>
            <Statistics>
              {item.preamble.map((preamble, index) => (
              <Statistic key={index}>
                <Value>{preamble.number}</Value>
                <Key>{preamble.specification}</Key>
              </Statistic>
              ))}
            </Statistics>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};