import React, { useState } from 'react';
import 'antd/dist/antd.css';
import { Modal, Button } from 'antd';
import { Row,Col } from 'antd';

const App = () => {
    const [visible, setVisible] = useState(false);
    return (
      <>
        <Button type="primary" onClick={() => setVisible(true)}>
          Buy Now
        </Button>
        <Modal
          title=""
          centered
          visible={visible}
          onOk={() => setVisible(false)}
          onCancel={() => setVisible(false)}
          footer={null}
          width={600}
        >
          <Row>
            <Col span={12}>
          <h1 style={{fontSize:'30px',fontWeight:'800'}}>Contact Info</h1>
          <p>Saree6.com</p>
          <p>saree6.com@gmail.com</p>
          <p>+91 97400 16279</p>
          <p>email/call to know price</p>
          </Col>
          <Col span={12}>
          <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/d/d0/QR_code_for_mobile_English_Wikipedia.svg/1200px-QR_code_for_mobile_English_Wikipedia.svg.png" alt=""  width="200px" height="200px"/>
          </Col>
          </Row>
        </Modal>
      </>
    );
  };

export default App;