import React from 'react'
import './style.css';
import {config} from "../../../Config/Config.js"; 

export default function BlogType2 ({props,
    item = props }) {
        
//   const CardData = [
    
//     {
//      "id": "01",
//      "img":"http://www.webcoderskull.com/img/team4.png",
//      "name":"Skull",
//      "title":"Freelance Web Developer"
    
//   },

//   {
//      "id": "02",
//      "img":"http://www.webcoderskull.com/img/team1.png",
//      "name":"Kappua",
//      "title":"Freelance Web Developer"
 
//   },
//   {
//     "id": "03",
//     "img":"http://www.webcoderskull.com/img/team4.png",
//     "name":"Manish",
//     "title":"Freelance Web Developer"
//  },

//  {
//   "id": "04",
//   "img":"http://www.webcoderskull.com/img/team2.png",
//   "name":"Atul",
//   "title":"Freelance Web Developer"

//   }
//  ]
    return (
        <div>         
            <section className="our-webcoderskull padding-lg">
            <div className="container">
              <div className="row heading heading-icon">
               <h2>{item.title}</h2>  
              </div>
                <ul className="row">
               <>
                <li className="col-12 col-md-6 col-lg-3">
                <div className="cnt-block equal-hight" style={{"height":"349px"}}>
                <figure>
                {item.blog.map((item) => (
                <img src={`${config.apiurl}${item.image.url}`} className="img-responsive" alt="img" />
                ))}
                </figure>
                {item.blog.map((item) => (
                <h3><a href="http://www.webcoderskull.com/">{item.title}</a></h3>
                ))}
                <p>{item.subtitle}</p>
                {/* <ul className="follow-us clearfix">
                <li>
                    <a href="/"><i className="fa fa-facebook" aria-hidden="true" /></a>
                </li>
                <li>
                    <a href="#"><i className="fa fa-twitter" aria-hidden="true" /></a>
                </li>
                <li>
                    <a href="#"><i className="fa fa-linkedin" aria-hidden="true" /></a>
                </li>
                </ul>   */}
                </div>
                </li>
                </>
                </ul>
            </div>
            </section>
        </div>    
    )
}


