import React from "react";
import tw from "twin.macro";
// import { config } from "../../../Config/Config.js";
// import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading } from "../../../assets/misc/Headings.js";
import { Container as ContainerBase, ContentWithPaddingXl } from "../../../assets/misc/Layouts";
import { SectionDescription } from "../../../assets/misc/Typography";
import Fade from 'react-reveal/Fade';
import "./count.css";

const Container = tw(ContainerBase)`my-8 lg:my-10 bg-gray-900 text-gray-100 -mx-8 px-8 bg-black`;
const HeadingContainer = tw.div``;
const Heading = tw(SectionHeading)`sm:text-3xl md:text-4xl lg:text-5xl text-gray-100`;
// const Subheading = tw(SubheadingBase)`text-gray-100 text-center`;
const Description = tw(SectionDescription)`text-gray-400 text-center mx-auto max-w-screen-md`;

const StatsContainer = tw.div`mt-8 flex flex-col sm:flex-row items-center justify-center flex-wrap max-w-screen-md justify-between mx-auto`
const Stat = tw.div`flex flex-col text-center p-4 tracking-wide`
const StatKey = tw.div`text-xl font-medium`
const StatValue = tw.div`text-4xl sm:text-3xl md:text-4xl lg:text-5xl font-black`

export default function CountupType1 ({
  subheading = "",
  heading = "Over 9000 Projects Completed",
  description = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  props,
  item =props,
  
  stats = [
    {
      key: "Clients",
      value: "2500+",
    },
    {
      key: "Revenue",
      value: "$100M+",
    },
    {
      key: "Employees",
      value: "150+",
    },
  ]
})  {
  return (
    <Container className="count-container">
      <Fade left>
      <ContentWithPaddingXl className="count-content">
        <HeadingContainer>
          {/* {subheading && <Subheading>{subheading}</Subheading>} */}
          <Heading>{item.title}</Heading>
          {description && <Description className="count-description">{item.description}</Description>}
        </HeadingContainer>
        <StatsContainer className="count-countcontainer">
          {item.countup.map((stat, index) => (
            <Stat className="count-stat" key={index}>
              <StatValue>{stat.countnumber}+</StatValue>
              <StatKey>{stat.countname}</StatKey>
            </Stat>
          ))}
        </StatsContainer>
      </ContentWithPaddingXl>
      </Fade>
    </Container>
  );
};
