import React from 'react'
import './style.css'


export default function HerocardType3 ({props,
item = props}) {
    return (
        <div>
            <div id="about" class="section about-us wf-section">
            <img src="https://assets.website-files.com/5c8e14754e2db80389b0451c/5d42ef4055b0f558a2085339_Makeit%20Bow059.jpg" width="1082" srcset="https://assets.website-files.com/5c8e14754e2db80389b0451c/5d42ef4055b0f558a2085339_Makeit%20Bow059-p-500.jpeg 500w, https://assets.website-files.com/5c8e14754e2db80389b0451c/5d42ef4055b0f558a2085339_Makeit%20Bow059-p-1080.jpeg 1080w, https://assets.website-files.com/5c8e14754e2db80389b0451c/5d42ef4055b0f558a2085339_Makeit%20Bow059.jpg 1900w" sizes="100vw" alt="" class="about-us-bg"/>
            <div class="section-container lines">
            <div class="about-us-texts-wrapper">
                
                <p class="large-paragraph">{item.title}</p><p class="large-paragraph">{item.description}</p></div>
            <div class="lines">
            <div class="tickerwrapper">
            <div class="w-embed">
            <div class="marquee">
            <div class="track">
            <div>
        <span class="pinklines"> {item.subtitle}</span>
        {/* <span class="yellowlines"> - some text </span>
        <span class="bluelines"> - some &amp; text</span>
           <span class="whitelines"> - some text</span>
        <span class="yellowlines"> - some text</span>
        <span class="bluelines"> - some &amp; text</span>
        <span class="pinklines"> - some text</span>
        <span class="yellowlines"> -some text </span>
        <span class="bluelines"> - some &amp; text</span>
        <span class="whitelines"> - some text</span>
        <span class="yellowlines"> - some text</span>
        <span class="bluelines"> - some &amp; text</span>
        <span class="pinklines"> - some text</span>
        <span class="yellowlines"> - some text </span>
        <span class="bluelines"> - some &amp; text</span>
        <span class="whitelines"> -some text</span>
        <span class="yellowlines"> - some text</span>
        <span class="bluelines"> - some &amp; text</span>     */}
</div>
</div>
</div></div></div><div class="tickerright"><div class="w-embed"><div class="marquee-two">
  <div class="track-two">
  <div>
        <span class="bluelines"> {item.subdescription} </span>
        {/* <span class="whitelines"> - some text &amp; some text</span>
        <span class="yellowlines"> - some text &amp; Ssome textS</span>
        <span class="bluelines"> - some text &amp; some text</span>
        <span class="whitelines"> - some text</span>
        <span class="bluelines"> - some text </span>
        <span class="whitelines"> - some text &amp; some text</span>
        <span class="yellowlines"> - some text &amp; some text</span>
        <span class="bluelines"> - some text &amp; some text</span>
        <span class="whitelines"> -some text</span>
        <span class="bluelines"> - some text </span>
        <span class="pinklines"> - some text &amp; some text</span>
        <span class="yellowlines"> - some text &amp; some text</span>
        <span class="bluelines"> - some text &amp; some text</span>
        <span class="whitelines"> -some text</span>       */}
</div>
</div>
</div></div></div><div class="tickerlast"><div class="w-embed"><div class="marquee">
  <div class="track">
  <div>
        <span class="yellowlines">{item.subdescription} </span>
        <span class="bluelines"> - some &amp; text</span>
        <span class="pinklines"> - some &amp; text</span>
        <span class="yellowlines"> -some &amp; text</span>
        <span class="bluelines"> - some text</span>
        <span class="pinklines"> - some&amp; text</span>
        <span class="yellowlines"> -some text </span>
        <span class="bluelines"> - some &amp; text</span>
        <span class="pinklines"> - some &amp; text</span>
        <span class="yellowlines"> - some &amp; text</span>
        <span class="bluelines"> -some text</span>
        <span class="whitelines"> - some &amp; text</span>
        <span class="yellowlines"> - some text </span>
        <span class="bluelines"> - some &amp; text</span>
        <span class="pinklines"> - some &amp; text</span>
        <span class="yellowlines"> - some &amp;text</span>
        <span class="bluelines"> - some text</span>
        <span class="whitelines"> -some &amp;text</span>
           
</div>
</div>
</div></div></div></div></div></div>
        </div>
    )
}
