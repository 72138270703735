import React,{Component} from "react";
import tw from "twin.macro";
import { config } from "../../../Config/Config.js";
import styled from "styled-components";
// import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading} from "../../../assets/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "../../../assets/misc/Buttons.js";
// import EmailIllustrationSrc from "../../../assets/Images/email-illustration.svg";
import Fade from 'react-reveal/Fade';
import { Form, Input} from 'antd';
// import { useState } from "react";
import "./form.css"

const ColumnContainer = tw.div`hidden`

const Container = tw.div`relative p-5`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
// const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 float-right pl-52 w-screen`;
const ImageContainer = tw(ColumnContainer)``; //`lg:mt--6 lg:mt-0 lg:ml--4 px-4 sm:w-0 lg:w-2/3 md:w-0`;
const TextColumn = styled(Column)(props => [
  tw`md:w-full mt-16 md:mt-0 `,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

// const Image = styled.div(props => [
//   `background-image: url("${props.imageSrc}");`,
//   tw`rounded bg-contain bg-no-repeat bg-center h-full`,
// ]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

// const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(SectionHeading)`text-gray-100 mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-pink-100`

//const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col max-w-sm mx-auto md:mx-0 rounded`
// const Input = tw.input`mt-6 first:mt-0 border-b-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-gray-500`
// const Textarea = styled(Input).attrs({as: "textarea"})`
//   ${tw`h-24`}
// `

const SubmitButton = tw(PrimaryButtonBase)`inline-block mt-8`
const parseJSON = resp => (resp.json ? resp.json() : resp);

const checkStatus = resp => {
  if (resp.status >= 200 && resp.status < 300) {
    return resp;
  }
  return parseJSON(resp).then(resp => {
    throw resp;
  });
};

// const headers = {
//   'Content-Type': 'application/json',
// };

class FormType1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form:'',
      id:0,
      formId:'',
    item : props,
    email:'',
    modifiedData : {},
    error:null
    };
}
formRef = React.createRef();
handleSubmit = async () => {
 const dataToSend = {formId :this.state.id, data:this.state.modifiedData}
  //e.preventDefault();
  console.log(dataToSend);

  console.log(this.state.modifiedData,"Form submitted:", this.formRef, Form);
  this.formRef.current.resetFields();
  // message.success('data submited successfully');
  try {
    await fetch(`${config.apiurl}/form-data`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(dataToSend),
    })
      .then(checkStatus)
      .then(parseJSON);
  } catch (error) {
    this.setState({ error });
  }
};

validateEmail=(email) =>{
  var regex = /^([a-z0-9!#$%&'*+\-/=?^_`{|}~]+(?:\.[a-zA-Z0-9!#$%&'*+\-/=?^_`{|}~]+)*)@((?:[a-z0-9]+(?:[a-z-0-9-]*)\.)+[a-z]{2,})$/gi;
  return regex.test(email);
}

onChange = (field, value) => {
  console.log(field);
  this.setState
  (prev => ({
    ...prev,
    modifiedData: { 
      ...prev.modifiedData, 
      [field]: value 
    }
  }));
  console.log(value);
};

handleInputChange = ({ target: { name, value } }) => {
  this.setState(prev => ({
    ...prev,
    modifiedData: {
      ...prev.modifiedData,
      [name]: value,
    },
  }));
  console.log(value);
};

render(){
//  const { modifiedData} = this.state;
  return (
    <Container id="contact" className="form-cnt">
      <Fade right>
      <TwoColumn className="form-column"> 
        <TextColumn className="form-text-column">
          <TextContent>
            {/* {subheading && <Subheading>{subheading}</Subheading>} */}
            <Heading>{this.props.item.title}</Heading>
            {this.props.item.description && <Description>{this.props.item.description
            }</Description>}
            <Form className="form-Form" name="multi-form" ref={this.formRef} onFinish={this.handleSubmit} >
            {/* {item.formdata.map((item) => ( */}
            {this.props.item.inputField.map((n) =>
            <>
              <Form.Item name={n.smalltext} rules={[{ required: true, message: `Enter ${n.smalltext}` }]} >
              <Input placeholder={n.smalltext} name={n.smalltext}  onChange={this.handleInputChange} style={{borderBottom:'1px solid ',backgroundColor:'black',color:'white',width:'100%',minHeight:'30px'}}  maxLength="100" />
            </Form.Item>
            <br/>
            </>
            )}
              {this.props.item.feedback.map((n) =>
              <>
                <Form.Item name={n.description} rules={[{ required: true, message: `Enter ${n.description}`, }]} >
                <Input.TextArea placeholder={n.description} name={n.description}  onChange={this.handleInputChange} style={{borderBottom:'1px solid',backgroundColor:'black',color:'white',borderBottomColor:'white',width:'100%',minHeight:'125px'}} maxLength="100"/>
              
            </Form.Item>
            <br/>
            </>
            )}
              <SubmitButton className="form-button"  type="submit">{this.props.item.Submit}</SubmitButton>      
            </Form> 
          </TextContent>
        </TextColumn>
        {/* <ImageColumn>
          {this.props.item.image ? 
          <>
          {<img className="form-img" src={`${config.apiurl}${this.props.item.image.url}`} alt="Contact Form"/> 
        }</>: null}
        </ImageColumn> */}
        <ImageContainer>
        {
        this.props.item.image ? 
          <>
            {
              <img src={`${config.apiurl}${this.props.item.image.url}`} alt='Contact Form'/>
        }</>: null
        }
          </ImageContainer>
      </TwoColumn>
      </Fade>
    </Container>
  );
  }
};

export default FormType1;