import React, { Component } from "react";
import { Nav, Navbar, Container, NavDropdown } from 'react-bootstrap';
import { HashLink as Link } from 'react-router-hash-link';
import "bootstrap/dist/css/bootstrap.css";
import "./type1.css"
import { config } from "../../../Config/Config.js";

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            item: props,
            style : {
                "width": "0%"
            },
            expanded: false
        };
        this.OpenNav = this.OpenNav.bind(this);
        this.CloseNav = this.CloseNav.bind(this);    
    }

    OpenNav = (style = this.state.style) => {
        style = { "width": "100%"};
        this.setState({ style }, ()=> console.log('OpenNav Set: ', this.state.style ));
        console.log('Get: ', this.state.style);
    }

    CloseNav = (style = this.state.style) => {
        style = { "width": "0%"};
        this.setState({ style }, ()=> console.log('CloseNav Set: ', this.state.style ));
        console.log('Get: ', this.state.style);
        this.CollapseNavbar();
    }

    ExpandNavbar = (expanded = this.state.expanded) => {
        console.log('Get: ', this.state.expanded);
        expanded = true;
        this.setState({ expanded }, ()=> console.log('SetExpanded Set: ', this.state.expanded ));
        console.log('Get: ', this.state.expanded);
    }  

    CollapseNavbar = (expanded = this.state.expanded) => {
        console.log('Get: ', this.state.expanded);
        expanded = false;
        this.setState({ expanded }, ()=> console.log('SetExpanded Set: ', this.state.expanded ));
        console.log('Get: ', this.state.expanded);
    }  
    render() {
        return (
            <>
                <Navbar collapseOnSelect={false} expand="lg" expanded={this.state.expanded} fixed="top" bg="dark" variant="dark">
                    <Container>
                        <Navbar.Brand href="#home">
                            <img
                                src={`${config.apiurl}${this.props.item.logo.url}`}
                                width="30"
                                height="30"
                                className="d-inline-block align-top"
                                alt="React Bootstrap logo"
                            /></Navbar.Brand>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={ this.state.expanded ? this.CollapseNavbar : this.ExpandNavbar }/>
                        {this.props.item.navdesign.name === 'type2' ?
                            <Navbar.Collapse id="responsive-navbar-nav">
                                <Nav>
                                    {this.props.item.navlink.map((n) =>
                                        <>
                                            {n.submenu.length > 0 ?
                                                <NavDropdown title={n.link} id="basic-nav-dropdown">
                                                    {n.submenu.map((n) =>
                                                        <NavDropdown.Item href="/">{n.name}</NavDropdown.Item>
                                                    )}
                                                </NavDropdown>
                                                :
                                                <Link className="pointer" to={`#${n.url}`} onClick={ this.CloseNav }>{n.link}</Link>
                                            }
                                        </>
                                    )}
                                </Nav>
                            </Navbar.Collapse>
                            : null}
                            {this.props.item.navdesign.name === 'type1' ?
                            <Navbar.Collapse className="right" id="responsive-navbar-nav">
                                <Nav >
                                    {this.props.item.navlink.map((n) =>
                                        <>
                                            {n.submenu.length > 0 ?
                                                <NavDropdown title={n.link} id="basic-nav-dropdown">
                                                    {n.submenu.map((n) =>
                                                        <NavDropdown.Item href="/">{n.name}</NavDropdown.Item>
                                                    )}
                                                </NavDropdown>
                                                :
                                                <Link className="pointer" to="/home" onClick={ this.CloseNav }>{n.link}</Link>
                                            }
                                        </>
                                    )}
                                </Nav>
                            </Navbar.Collapse>
                            : null}
                    </Container>

                </Navbar>
            </>
        )
    }

}

export default Header;