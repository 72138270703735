import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import "bootstrap/dist/css/bootstrap.css"
import NavBarRoutes from "./Routing";
import Loader from "./Components/Common/Loader";
// import Ab1 from "./Components/Feature/Type2"

ReactDOM.render(
  <>
    <NavBarRoutes />
    <Loader/>
{/* <Ab1/> */}
  </>,
  document.getElementById('root')
);


