import React, { useState } from 'react';
import 'antd/dist/antd.css';
import { Modal, Button } from 'antd';
import { Row, Col } from 'antd';
import { config } from "../../Config/Config.js"

const App = ({ props,
  item = props }) => {
  const [visible, setVisible] = useState(false);
  return (
    <>
      <Button  style={{marginTop:'20px'}} type="primary" onClick={() => setVisible(true)}>
      Learn More
      </Button>
      <Modal
        title=""
        centered
        visible={visible}
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        footer={null}
        width={800}
      >
        <Row>
          <Col span={12}>
            <h1 style={{ fontSize: '30px', fontWeight: '800' }}>Cotton Sarees - The Most Elegant Attire for Women
              Saree is</h1>
            <p>Saree is one of the most established types of garments on earth from back hundreds of years and its significance is still exceptionally solid. Shockingly, a large portion of the Western universes don‘t have the foggiest idea about the considerable noteworthiness of this dress. Every single Indian lady wanted to wore saree customarily and have awesome social criticalness. It is said to be a standout amongst the most exquisite ladies‘ dress and exhibits the most honorable path to the genuine bend, carefully secured the entire body of a lady. A lady in India dependably looks totally immaculate in saree, enlivened with wonderful adornments.</p>
            <p>If you are looking for the best cotton sarees manufacturers in jaipur then you can likewise go for online shopping. We Aarav Fabricators are the main producer and manufacturers in Jaipur giving our clients the best in vogue and novel plans of cotton saree in Jaipur.</p>
          </Col>
          <Col span={12}>
            <img  style={{objectFit:'fill'}} src={`${config.apiurl}${item.image.url}`} alt="" width="400px" height="1000px" />
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default App;