import React, { useState } from 'react';
import 'antd/dist/antd.css';
import { Modal, Button } from 'antd';
import { Row,Col } from 'antd';
import { config } from "../../Config/Config.js"

const App = () => {
    const [visible, setVisible] = useState(false);
    return (
      <>
        <Button type="primary" onClick={() => setVisible(true)}>
          MANUFACTURING PROCESS
        </Button>
        <Modal
          title=""
          centered
          visible={visible}
          onOk={() => setVisible(false)}
          onCancel={() => setVisible(false)}
          footer={null}
          bodyStyle={{height: '70vh'}}
          width={'70vw'}
        >
          <Row>
            {/* <Col> */}
          {/* <h1 style={{fontSize:'30px',fontWeight:'800'}}>Contact Info</h1> */}
          {/* <p>COMING SOON</p> */}
          {/* <p>saree6.com@gmail.com</p>
          <p>+91 97400 16279</p>
          <p>email/call to know price</p> */}
          {/* </Col> */}
          <Col>

          </Col>
          </Row>
          <img src={`${config.apiurl}/uploads/small_Process_Cotton_Saree_Manufacturing_c4dc9fe808.png`} alt="" width="100%"/>
        </Modal>
      </>
    );
  };

export default App;