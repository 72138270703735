import React, { Component } from 'react';
// import ReactDOM from 'react-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import './type1.css';
// import { Button } from 'react-responsive-button';
import 'react-responsive-button/dist/index.css';
// import HomeImg from './images/coverpicture.jpg';
// import CoverImage from './images/coverpicture1.jpg';
// import CoverImage1 from './images/coverpicture2.jpg';
// import { Link } from 'react-router-dom';
import { config } from "../../../Config/Config.js";


class CarouselCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            item: props.data,
        };
        console.log(props.data)
    }

  render() {
        return (

          <Carousel interval={4000} transitionTime={800} autoPlay={true} showIndicators={true} infiniteLoop={true} showArrows={false} showStatus={false} showThumbs={false} animationHandler="fade" swipeable={false}>
                {this.props.item.carousel_data.map((n) => 
                <div>
                    {n.image.map((image) => 
                    
                    <img src={`${config.apiurl}${image.formats.medium? image
                        .formats.medium.url: image.url}`} alt="saree" style={{height:'98vh'}}  />  
                    
                    )}
                
                <h2 className="legend">{n.title}</h2>
                <p className="legend2">{n.description}</p>
                
            </div>
            )}  
           </Carousel>   
        );
    }
}
export default CarouselCard;