import React from 'react'
import './style.css'

// const ServicesData = [
    
//   {
//    "id": "01",
//    "heading":"heading",
//    "description":"Some text goes here"
// },

// {
//    "id": "02",
//    "heading":"heading",
//    "description":"Some text goes here"
// },
// {
//   "id": "03",
//    "heading":"heading",
//   "description":"Some text goes here"
// },

// {
// "id": "04",
// "heading":"heading",
// "description":"Some text goes here"

// }
// ]
export default function HeroCardType2 ({props,
 item = props}) {
    return (
        <div>
            <div className="services-section black-trans-bg">
  <div className="services-section-banner" />
  <div className="container">
    <div className="row">
      <div className="col-md-8 col-md-offset-4 col-sm-12 col-xs-12">
        <div className="services-head">
          <h2 className="services-title">{item.title}</h2>
          <span className="services-title-border" />
          <p className="services-text">{item.description}</p>
        </div>
        <div className="services-content">
          <div className="row">
         
            <div className="col-md-6 col-sm-6 col-xs-12">
              <div className="service-2">
                <div className="service-box">
                  <div className="clearfix">
                    <div className="iconset">
                      <span className="glyphicon glyphicon-cog icon" />
                    </div>  
                    <div className="servicesB-content">
                   
                      <h4>{item.subtitle}</h4>
                      <p>{item.subdescription}</p>  
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>   
        </div>
      </div>
    </div>
  </div>
</div>
        </div>
    )
}



