import React,{Component} from "react";
import styled from "styled-components";
import tw from "twin.macro";
import "./type1.css";
// import { ReactComponent as SvgDotPatternIcon } from "../components/images/dot-pattern.svg";
import { SectionHeading as HeadingTitle } from "../../../assets/misc/Headings";
import Fade from 'react-reveal/Fade';
import { config } from "../../../Config/Config";

const Container = tw.div`relative `;

const SingleColumn = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
// const HeadingDescription = tw.p`mt-4 font-medium text-gray-600 text-center max-w-sm`;

const Content = tw.div`mt-16`;

const Card = styled.div(props => [
  tw`mt-24 md:flex justify-center items-center`,
  props.reversed ? tw`flex-row-reverse` : "flex-row"
]);
// const Image = styled.div(props => [
//   `background-image: url("${props.imageSrc}");`,
//   tw`rounded md:w-1/2 lg:w-5/12 xl:w-1/3 flex-shrink-0 h-80 md: bg-cover bg-center mx-4 sm:mx-8 md:mx-4 lg:mx-8`
// ]);
const Details = tw.div`mt-4 md:mt-0 md:max-w-md mx-4 sm:mx-8 md:mx-4 lg:mx-8`;
// const Subtitle = tw.div`font-bold tracking-wide text-gray-100`;
const Title = tw.h4`text-3xl font-bold text-gray-400`;
const Description = tw.p`mt-2 text-sm leading-loose`;
// const Link = tw.a`inline-block mt-4 text-sm text-gray-500 font-bold cursor-pointer transition duration-300 border-b-2 border-transparent hover:border-gray-500`;


  class App extends Component {
    constructor(props) {
      console.log(props);
      super(props);
      this.state = {
        item: props.data,
      };
    }

    render(){
  return (
    <Container id="about" className="saree6-container">
            <Fade left>
      <SingleColumn className="about-container">
        <HeadingInfoContainer>
          <HeadingTitle className="about-heading text-color" id={this.props.item.title} >{this.props.item.title}</HeadingTitle>
          {/* <HeadingDescription>
          Sarees are should be extraordinary in bunch ways like creases and the pallu. Appropriate from huge themes to little themes, ethereal arrangements can be offered from the presumed online stores.
          </HeadingDescription> */}
        </HeadingInfoContainer>

        <Content className="about-content">
          {/* {cards.map((card, i) => ( */}
            <Card className="about-card">
              <Details className="about-description">
                {/* <Subtitle>{this.props.item.heading}</Subtitle> */}
                <Title className="about-title">{this.props.item.Heading}</Title>
                <Description className="about-details">{this.props.item.description}</Description>
                {/* <Link href={card.url}>See Event Details</Link> */}
              </Details>
              {this.props.item.image.map((n) =>
              <img className="about-image" src={`${config.apiurl}${n.url}`} alt="img" />
              )}
            </Card>
           {/* ))} */}
        </Content>
      </SingleColumn>
      {/* <SvgDotPattern1 />
      <SvgDotPattern2 />
      <SvgDotPattern3 />
      <SvgDotPattern4 /> */}
      </Fade>
    </Container>
  );
    }
}

export default App;