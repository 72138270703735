// import { render } from '@testing-library/react';
import React, { Component } from 'react';
// import NavBar from "../NavBar"
import "tailwindcss/dist/base.css";
import "./styles/globalStyles.css";
import Header from './Components/Navbar/Type1';
import CarouselCard from './Components/Carouselcard/Type1';
import About from './Components/About/Type1';
import TestimonialCard from "./Components/Testimonial/Type1";
import TestimonialType2 from './Components/Testimonial/Type2';
import Feature from "./Components/Feature/Type1";
import TestimonialType3 from "./Components/Testimonial/Type3"
import Richcard from "./Components/Richcard/Type1";
import Blog from "./Components/blog/type1";
import Tab from "./Components/Tab/Type2";
import Profile from "./Components/profile/type1";
import Countup from "./Components/Countup/Type1";
import Accordion from "./Components/Accordion/Type1"
import AccordionType2 from "./Components/Accordion/Type2";
import Footer from "./Components/Footer/Type1";
import Footer2 from "./Components/Footer/Type2"
import Form from "./Components/Form/Type1";
import Ctasection from "./Components/Ctasection/Type1";
import Herocard from "./Components/Herocard/Type1";
import Preamblecard from "./Components/preamblecard/Type1";
import Statscard from "./Components/statscard/Type1"
import { config } from './Config/Config';
// import RichCard from "./Components/Richcard/Type2"
import Countup1 from "./Components/Countup/Type3";
import RichcardType3 from "./Components/Richcard/Type3";
import BlogType2 from "./Components/blog/type2";
import Card from "./Components/Card/Type9"
import HerocardType2 from "./Components/Herocard/Type2";
import HerocardType3 from "./Components/Herocard/Type3";



const parseJSON = resp => (resp.json ? resp.json() : resp);

const checkStatus = resp => {
    if (resp.status >= 200 && resp.status < 300) {
        return resp;
    }
    return parseJSON(resp).then(resp => {
        throw resp;
    });
};

const headers = {
    'Content-Type': 'application/json',
};
// const data = [];
// for (let i = 0; i < 100; i++) {
//     data.push({
//         key: i.toString(),
//         name: `Edrward ${i}`,
//         age: 32,
//         address: `London Park no. ${i}`,
//     });
// }

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // isLoading:false,
            // customId:'amit-1',
            AboutData: [],
            CustomerData:[],
            NavbarData: [],
            TestimonialData: [],
            FooterData: [],
            FeatureData: [],
            CarouselData: [],
            RichcardData: [],
            AccordionData: [],
            BlogData: [],
            TabData: [],
            ProfileData: [],
            CountupData: [],
            FormData: [],
            CtasectionData: [],
            HerocardData: [],
            PreamblecardData: [],
            StatscardData: [],
            CardData: [],


        };
    }

    componentDidMount = async () => {



        try {
            const NavbarData = await fetch(`${config.apiurl}/navbars`, {
                method: 'GET',
                headers: headers,
            })
                .then(checkStatus)
                .then(parseJSON);
            this.setState({ NavbarData });
        } catch (error) {
            this.setState({ error });
        }

        try {
            const CarouselData = await fetch(`${config.apiurl}/carousels`, {
                // const CarouselData = await fetch('http://localhost:1337/carousels`, {
                method: 'GET',
                headers: headers,
            })
                .then(checkStatus)
                .then(parseJSON);
            this.setState({ CarouselData });
        } catch (error) {
            this.setState({ error });
        }

        try {
            const AboutData = await fetch(`${config.apiurl}/abouts`, {
                method: 'GET',
                headers: headers,
            })
                .then(checkStatus)
                .then(parseJSON);
            this.setState({ AboutData });
        } catch (error) {
            this.setState({ error });
        }

        try {
             const CountupData = await fetch(`${config.apiurl}/countups`, {    
                //  const CountupData = await fetch('http://localhost:1337/countups`, {
                method: 'GET',
                headers: headers,
            })
                .then(checkStatus)
                .then(parseJSON);
            this.setState({ CountupData });
        } catch (error) {
            this.setState({ error });
        }

        try {
            const HerocardData = await fetch(`${config.apiurl}/herocards`, {
                // const HerocardData = await fetch('http://localhost:1337/herocards`, {
                method: 'GET',
                headers: headers,
            })
                .then(checkStatus)
                .then(parseJSON);
            this.setState({ HerocardData });
        } catch (error) {
            this.setState({ error });
        }

        try {
            const PreamblecardData = await fetch(`${config.apiurl}/preamblecards`, {
                //  const PreamblecardData = await fetch('http://localhost:1337/preamblecards`, {
                method: 'GET',
                headers: headers,
            })
                .then(checkStatus)
                .then(parseJSON);
            this.setState({ PreamblecardData });
        } catch (error) {
            this.setState({ error });
        }

        try {
            const TabData = await fetch(`${config.apiurl}/tabs`, {
                // const TabData = await fetch('http://localhost:1337/tabs`, {
                method: 'GET',
                headers: headers,
            })
                .then(checkStatus)
                .then(parseJSON);
            this.setState({ TabData });
        } catch (error) {
            this.setState({ error });
        }

        try {
            const StatscardData = await fetch(`${config.apiurl}/statscards`, {
                // const TabData = await fetch('http://localhost:1337/tabs`, {
                method: 'GET',
                headers: headers,
            })
                .then(checkStatus)
                .then(parseJSON);
            this.setState({ StatscardData });
        } catch (error) {
            this.setState({ error });
        }


        try {
            const FeatureData = await fetch(`${config.apiurl}/features`, {
                method: 'GET',
                headers: headers,
            })
                .then(checkStatus)
                .then(parseJSON);
            this.setState({ FeatureData });
        } catch (error) {
            this.setState({ error });
        }

        try {
            const ProfileData = await fetch(`${config.apiurl}/profiles`, {
                // const ProfileData = await fetch('http://localhost:1337/profiles`, {
                method: 'GET',
                headers: headers,
            })
                .then(checkStatus)
                .then(parseJSON);
            this.setState({ ProfileData });
        } catch (error) {
            this.setState({ error });
        }

        try {
            const TestimonialData = await fetch(`${config.apiurl}/testimonials`, {
                method: 'GET',
                headers: headers,
            })
                .then(checkStatus)
                .then(parseJSON);
            this.setState({ TestimonialData });
        } catch (error) {
            this.setState({ error });
        }

        try {
            const CtasectionData = await fetch(`${config.apiurl}/cta-sections`, {
                // const CtasectionData = await fetch('http://localhost:1337/cta-sections`, {
                method: 'GET',
                headers: headers,
            })
                .then(checkStatus)
                .then(parseJSON);
            this.setState({ CtasectionData });
        } catch (error) {
            this.setState({ error });
        }


        try {
            this.setState({isLoading:true});
            const RichcardData = await fetch(`${config.apiurl}/richcards`, {
                // const RichcardData = await fetch('http://localhost:1337/reachcards`, {
                method: 'GET',
                headers: headers,
            })
                .then(checkStatus)
                .then(parseJSON);
            this.setState({ RichcardData });
            this.setState({isLoading:false});
        } catch (error) {
            this.setState({ error });
        }


        try {
            const AccordionData = await fetch(`${config.apiurl}/accordions`, {
                // const AccordionData = await fetch('http://localhost:1337/accordions`, {
                method: 'GET',
                headers: headers,
            })
                .then(checkStatus)
                .then(parseJSON);
            this.setState({ AccordionData });
        } catch (error) {
            this.setState({ error });
        }

        try {
            const BlogData = await fetch(`${config.apiurl}/blogs`, {
                // const BlogData = await fetch('http://localhost:1337/blogs`, {
                method: 'GET',
                headers: headers,
            })
                .then(checkStatus)
                .then(parseJSON);
            this.setState({ BlogData });
        } catch (error) {
            this.setState({ error });
        }

        try {
            const FormData = await fetch(`${config.apiurl}/forms`, {
                // const FormData = await fetch('http://localhost:1337/forms`, {
                method: 'GET',
                headers: headers,
            })
                .then(checkStatus)
                .then(parseJSON);
            this.setState({ FormData });
        } catch (error) {
            this.setState({ error });
        }

        try {
            const FooterData = await fetch(`${config.apiurl}/footers`, {
                method: 'GET',
                headers: headers,
            })
                .then(checkStatus)
                .then(parseJSON);
            this.setState({ FooterData });
        } catch (error) {
            this.setState({ error });
        }


        try {
            const CardData = await fetch(`${config.apiurl}/cards`, {
                // const CardData = await fetch('http://localhost:1337/cards`, {
                method: 'GET',
                headers: headers,
            })
                .then(checkStatus)
                .then(parseJSON);
            this.setState({ CardData });
        } catch (error) {
            this.setState({ error });
        }

    };

    render() {
        let menus = [];
        let abouts = [];
        let testimonials = [];
        let footers = [];
        let features = [];
        let carousels = [];
        let richcards = [];
        let blogs = [];
        let accordions = [];
        let tabs = [];
        let profiles = [];
        let countups = [];
        let forms = [];
        let ctasections = [];
        let herocards = [];
        let preamblecards = [];
        let statscards = [];
        let cards = [];
        let about;
        let menu;
        let testimonial;
        let footer;
        let feature;
        let carousel;
        let richcard;
        let accordion;
        let blog;
        let tab;
        let profile;
        let countup;
        let form;
        let ctasection;
        let herocard;
        let preamblecard;
        let statscard;
        let card;





        this.state.NavbarData.map((item) => {
            if (item.navdesign.name === 'type1') {
                menu = <Header item={item} />
                menus.push(menu);
                console.log('entered navbar type1');
            }
            else if (item.navdesign.name === 'type2') {
                menu = <Header item={item} />
                menus.push(menu);
                console.log('entered navbar type2');
            }
            // else if (item.design.name === 'design-1')
            // {
            //     about2 = ''
            //     abouts.push(about2);
            //     console.log('entered design-2');
            // }    
            return null;
        }
        )


        // about section



        this.state.AboutData.map((item) => {
            if (item.aboutdesign.name === 'type1') {
                about = <About item={item} />
                abouts.push(about);
                console.log('entered adout type1');
            } 
            // else if (item.aboutdesign.name === 'type2')
            // {
            //     about = <About2 item={item}/>
            //     abouts.push(about);
            //     console.log('entered about type2');
            // }
            return null;
        }
        )

        this.state.TestimonialData.map((item) => {
            if (item.testimonial_design.name === 'type1') {
                testimonial = <TestimonialCard item={item} />
                testimonials.push(testimonial);
                console.log('entered testimonial type1');
            }
            else if (item.testimonial_design.name === 'type2') {
                testimonial = <TestimonialType2 item={item} />
                testimonials.push(testimonial);
                console.log('entered testimonial type2');
            }
            else if (item.testimonial_design.name === 'type3') {
                testimonial = <TestimonialType3 item={item} />
                testimonials.push(testimonial);
                console.log('entered testimonial type3');
            }
            return null;


        }
        )

        this.state.FeatureData.map((item) => {
            if (item.featuredesign.name === 'type1') {
                feature = <Feature item={item} />
                features.push(feature);
                console.log('entered feature type1');
            }
            // else if (item.aboutdesign.name === 'card2')
            // {
            //     about = <About1 item={item}/>
            //     abouts.push(about);
            //     console.log('entered card2');
            // }
            return null;
        }
        )

        this.state.FooterData.map((item) => {
            if (item.footerdesign.name === 'type1') {
                footer = <Footer item={item} />
                footers.push(footer);
                console.log('entered footer type1');
            }
            else if (item.footerdesign.name === 'type2') {
                footer = <Footer2 item={item} />
                footers.push(footer);
                console.log('entered footer type2');
            }
            return null;
        }
        )
        this.state.RichcardData.map((item) => {
            if (item.richcarddesign.name === 'type1') {
                richcard = <Richcard item={item} />
                richcards.push(richcard);
                console.log('entered reachcard type1');
            }
            else if (item.richcarddesign.name === 'type3')
            {
                richcard = <RichcardType3 item={item}/>
                richcards.push(richcard);
                console.log('enteredrichcard type3');
            }
            return null;
        }
    )

        this.state.BlogData.map((item) => {
            if (item.blogdesign.name === 'type1') {
                blog = <Blog item={item} />
                blogs.push(blog);
                console.log('entered blog type1');
            }
            else if (item.blogdesign.name === 'type2')
            {
                blog = <BlogType2 item={item}/>
                blogs.push(blog);
                console.log('entered blog type2');
            }
            return null;
        }
        )
        this.state.AccordionData.map((item) => {
            if (item.accordiondesign.name === 'type1') {
                accordion = <Accordion item={item} />
                accordions.push(accordion);
                console.log('entered accordion type1');
            }
            else if (item.accordiondesign.name === 'type2') {
                accordion = <AccordionType2 item={item} />
                accordions.push(accordion);
                console.log('entered type2');
            }
            return null;
        }
        )

        this.state.ProfileData.map((item) => {
            if (item.profiledesign.name === 'type1') {
                profile = <Profile item={item} />
                profiles.push(profile);
                console.log('entered profile type1');
            }
            // else if (item.aboutdesign.name === 'card2')
            // {
            //     about = <About1 item={item}/>
            //     abouts.push(about);
            //     console.log('entered card2');
            // }
            return null;
        }
        )

        this.state.CountupData.map((item) => {
            if (item.countupdesign.name === 'type1') {
                countup = <Countup item={item} />
                countups.push(countup);
                console.log('entered countup type1');
            }
            else if (item.countupdesign.name === 'type3')
            {
                countup = <Countup1 item={item}/>
                countups.push(countup);
                console.log('entered type3');
            }
            return null;
        }
        )


        this.state.CarouselData.map((item) => {
            if (item.carouseldesign.name === 'type1') {
                carousel = <CarouselCard item={item} />
                carousels.push(carousel);
                console.log('entered carousel type1');
            }
            // else if (item.aboutdesign.name === 'card2')
            // {
            //     about = <About1 item={item}/>
            //     abouts.push(about);
            //     console.log('entered card2');
            // }
            return null;
        }
        )

        this.state.TabData.map((item) => {
            if (item.tabdesign.name === 'type1') {
                tab = <Tab item={item} />
                tabs.push(tab);
                console.log('entered tab type1');
            }
            // else if (item.navdesign.name === 'type2') {
            //     menu = <Header item={item} />
            //     menus.push(menu);
            //     console.log('entered navbar type2');
            // }
            // else if (item.design.name === 'design-1')
            // {
            //     about2 = ''
            //     abouts.push(about2);
            //     console.log('entered design-2');
            // }    
            return null;
        }
        )
        this.state.FormData.map((item) => {
            if (item.formdesign.name === 'type1') {
                form = <Form item={item} />
                forms.push(form);
                console.log('entered form type1');
            }
            // else if (item.aboutdesign.name === 'card2')
            // {
            //     about = <About1 item={item}/>
            //     abouts.push(about);
            //     console.log('entered card2');
            // }


            return null;
        }
        )
 
         
        this.state.HerocardData.map((item) => {
            if (item.herodesign.name === 'type1') {
                herocard = <Herocard item={item} />
                herocards.push(herocard);
                console.log('entered herocard type1');
            }
             else if (item.herodesign.name === 'type2')
             {
                 herocard = <HerocardType2 item={item}/>
                 herocards.push(herocard);
                 console.log('entered herocard type2');
             }
             else if (item.herodesign.name === 'type3')
             {
                 herocard = <HerocardType3 item={item}/>
                 herocards.push(herocard);
                 console.log('entered herocard type3');
             }
            return null;
        }
        )

        this.state.CtasectionData.map((item) => {
            if (item.ctasectiondesign.name === 'type1') {
                ctasection = <Ctasection item={item} />
                ctasections.push(ctasection);
                console.log('entered ctasection type1');
            }
            // else if (item.aboutdesign.name === 'card2')
            // {
            //     about = <About1 item={item}/>
            //     abouts.push(about);
            //     console.log('entered card2');
            // }
            return null;
        }
        )

        this.state.PreamblecardData.map((item) => {
            if (item.preamblecarddesign.name === 'type1') {
                preamblecard = <Preamblecard item={item} />
                preamblecards.push(preamblecard);
                console.log('entered preamblecard type1');
            }
            // else if (item.aboutdesign.name === 'card2')
            // {
            //     about = <About1 item={item}/>
            //     abouts.push(about);
            //     console.log('entered card2');
            // }
            return null;
        }
        )

        this.state.StatscardData.map((item) => {
            if (item.statscarddesign.name === 'type1') {
                statscard = <Statscard item={item} />
                statscards.push(statscard);
                console.log('entered statscard type1');
            }
            // else if (item.aboutdesign.name === 'card2')
            // {
            //     about = <About1 item={item}/>
            //     abouts.push(about);
            //     console.log('entered card2');
            // }
            return null;
        }
        )

        this.state.CardData.map((item) => {
            if (item.carddesign.name === 'type9') {
                card = <Card item={item} />
                cards.push(card);
                console.log('entered card type9');
            }
            // else if (item.aboutdesign.name === 'card2')
            // {
            //     about = <About1 item={item}/>
            //     abouts.push(about);
            //     console.log('entered card2');
            // }
            return null;
        }
        )

        return (
            <>
                {menus}
                {carousels}
                {abouts}
                {countups}
                {herocards}
                {preamblecards}
                {blogs}
                {tabs}
                {richcards}
                {statscards}
                {cards}
                {features}
                {testimonials}
                {ctasections}
                {profiles}
                {forms}
                {accordions}
                {footers}
            </>
        )
    }
}

export default App;
