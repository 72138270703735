import React from "react";
import Loader from "react-loader-spinner";
// import styled from "styled-components";

// const LoaderComponent = styled.div`
// position: fixed;

// `
export default class App extends React.Component {
  //other logic
  render() {
    return (
        // <LoaderComponent>
      <Loader
        type="ThreeDots"
        color="#00BFFF"
        height={400}
        width={400}
        timeout={2500} //3 secs
      />
    //   </LoaderComponent>
    );
  }
}