import React from 'react'
import './style.css'

// const CardData = [

//     {
//         'id':'1',
//         'sno':'01',
//         'title':'main title',
//         'description':'some features'
//     },
//     {
//         'id':'2',
//         'sno':'02',
//         'title':'main title',
//         'description':'some features'
//     },
//     {
//         'id':'3',
//         'sno':'03',
//         'title':'main title',
//         'description':'some features'
//     },
//     {
//         'id':'4',
//         'sno':'04',
//         'title':'main title',
//         'description':'some features'
//     },
//     {
//         'id':'5',
//         'sno':'05',
//         'title':'main title',
//         'description':'some features'
//     },
//     {
//         'id':'6',
//         'sno':'06',
//         'title':'main title',
//         'description':'some features'
//     }
// ]



export default function CountupType3  ({props,
    item = props }) {
    return (
        <div>
             <div className="container mt-5 mb-5">
            <div className="wrapper">
            <h4 className="mb-5">WHAT WE DO</h4>
            </div>
            <div className="row mb-5 ">
            
              <> 
            <div className="col-md-4 ">
            
            <div className="card1  item" style={{width: '20rem'}}>
                <div className="card-body">
                    {item.countup.map ((item ) => ( 
                      <>  
                    <h1 className="cardnumb">{item.countnumber}</h1>  
                    </>
                    ))}     
                    <h6 className="card-subtitle mb-3 text-muted ">{item.title}</h6>
                    <h3 className="card-title">{item.description}</h3> 
                    </div>
            </div>  
            </div>
            </>  
            </div>
            </div>
        </div>
    
    
    )
}

