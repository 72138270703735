import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { config } from "../../../Config/Config.js";
// import "./testimonial.css";
import { css } from "styled-components/macro"; //eslint-disable-line
import { ContentWithPaddingXl, Container } from "../../../assets/misc/Layouts.js";
import { SectionHeading as Heading } from "../../../assets/misc/Headings.js";
import { ReactComponent as SvgDecoratorBlob1 } from "../../../assets/Images/svg-decorator-blob-7.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "../../../assets/Images/svg-decorator-blob-8.svg";
import Fade from 'react-reveal/Fade';


// const Subheading = tw(SubheadingBase)`text-center`;
const Testimonials = tw.div`flex flex-col lg:flex-row items-center lg:items-stretch`;
const TestimonialContainer = tw.div`mt-16 lg:w-1/3`;
const Testimonial = tw.div`px-4 text-center max-w-xs mx-auto flex flex-col items-center`;
const Image = tw.img`w-20 h-20 rounded-full`;
const Quote = tw.blockquote`mt-2 text-gray-100 font-medium leading-loose`;
const CustomerName = tw.p`mt-4 text-gray-400 font-semibold uppercase text-sm tracking-wide`;
const CustomerDesignation = tw.p`mt-1 text-gray-100 font-semibold uppercase text-sm tracking-wide`;

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none  absolute left-0 top-0 h-56 w-56 opacity-25 transform -translate-x-2/3 -translate-y-12 text-pink-400`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none  absolute right-0 bottom-0 h-64 w-64 opacity-25 transform translate-x-2/3 text-yellow-500`}
`;

export default function TestimonialType2 ({
  subheading = "Customers",
  heading = "Trust Us.",
  props,
  item = props,
  testimonials = [
    {
      imageSrc:
        "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3.25&w=512&h=512&q=80",
      quote:
        "I was utterly impressed by the great quality, reasonable cost and timely delivery of Sarees to the our hometown. I highly recommend this store for buying Cotton Sarees that is in line with the latest trends and if a dress is required to be delivered oversees as well.",
      customerName: "Charlotte Hale"
    },
    {
      imageSrc:
        "https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=512&h=512&q=80",
      quote:
      "I was utterly impressed by the great quality, reasonable cost and timely delivery of Sarees to the our hometown. I highly recommend this store for buying Cotton Sarees that is in line with the latest trends and if a dress is required to be delivered oversees as well.",
      customerName: "Adam Cuppy"
    },
    {
      imageSrc:
        "https://images.unsplash.com/photo-1580852300654-03c803a14e24?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4.25&w=512&h=512&q=80",
      quote:
      "I was utterly impressed by the great quality, reasonable cost and timely delivery of Sarees to the our hometown. I highly recommend this store for buying Cotton Sarees that is in line with the latest trends and if a dress is required to be delivered oversees as well.",
      customerName: "Steven Marcetti"
    }
  ]
})  {
  return (
  
    <Container>
      <Fade left>
      <ContentWithPaddingXl>
        {/* {subheading && <Subheading>{subheading}</Subheading>} */}
        <Heading>{item.title}</Heading>
        <Testimonials>
        {item.testimonial_card.map((testimonial, index) => (
            <TestimonialContainer key={index}>
              <Testimonial>
              <Image src={`${config.apiurl}${testimonial.photo.url}`} />
                <CustomerName>{testimonial.name}</CustomerName>
                <CustomerDesignation>{testimonial.designation}</CustomerDesignation>
                <Quote>"{testimonial.description}"</Quote>
              </Testimonial>
            </TestimonialContainer>
            
          ))}
        </Testimonials>
      </ContentWithPaddingXl>

      <DecoratorBlob1 />
      <DecoratorBlob2 />
      </Fade>
    </Container>

  );
};