import React from 'react'
import {Row, Col} from 'react-bootstrap'
import './style.css'


export default function RichcardType3  ({props,
    item = props }) {
//  const CardData = [
    
//       {
//        "id": "01",
//        "img":"https://placeimg.com/380/230/nature",
//        "heading":"heading",
//        "title":"Heading",
//        "description":"Some text goes here"
//     },

//     {
//        "id": "02",
//        "img":"https://placeimg.com/380/230/animals",
//        "heading":"heading",
//        "title":"Heading",
//        "description":"Some text goes here"
//     },
//     {
//       "id": "03",
//       "img":"https://placeimg.com/380/230/tech",
//       "heading":"heading",
//       "title":"Heading",
//       "description":"Some text goes here"
//    },

//    {
//     "id": "04",
//     "img":"https://placeimg.com/380/230/nature",
//     "heading":"heading",
//     "title":"Heading",
//     "description":"Some text goes here"
 
    
//     }
//    ]

 return (
  
        <div>          
        <section className="details-card">
         <div className="container">
         <Row>           
              <>
                <Col md= '4'>
                  <div className="card-content mb-5">  
                   <div className="card-img">
                   {item.Richcard.map ((item) => ( 
                      <> 
                    <img imageSrc={`http://localhost:1337${item.image.url}`} alt="Img" />
                    <span><h4>{item.title}</h4></span>
                    </>
                    ))}
                   </div>
                   {item.Richcard.map ((item ) => ( 
                  <div className="card-desc">
                    <h3>{item.sub_heading}</h3>
                    <p>{item.discription}</p>          
                    <a href="/"  className="btn-card">Read</a>  
                   </div>
                   ))}
                   </div>
                   </Col>
              </>
               
             </Row>
            </div>
        </section>
        </div>
        
        
    )
             
}

