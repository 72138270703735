import React from "react";
import tw from "twin.macro";
import { config } from "../../../Config/Config.js";
import "./type1.css";
import styled from "styled-components";
// import { css } from "styled-components/macro"; //eslint-disable-line
import { ReactComponent as FacebookIcon } from "../../../assets/Images/facebook-icon.svg";
import { ReactComponent as TwitterIcon } from "../../../assets/Images/twitter-icon.svg";
import { ReactComponent as YoutubeIcon } from "../../../assets/Images/youtube-icon.svg";
import Slide from 'react-reveal/Slide';

const Container = tw.div`relative bg-gray-200 -mx-8 -mb-8 px-8`;
const FiveColumns = tw.div`max-w-screen-xl mx-auto py-16 lg:py-20 flex flex-wrap justify-between`;

const Column = tw.div`md:w-1/5`;
const WideColumn = tw(Column)`text-center md:text-left w-full md:w-2/5 mb-10 md:mb-0`;

const ColumnHeading = tw.h5`font-bold`;

const LinkList = tw.ul`mt-4 text-sm font-medium`;
const LinkListItem = tw.li`mt-3`;
const Link = tw.a`border-b-2 border-transparent hocus:text-gray-500 hocus:border-gray-500 pb-1 transition duration-300`;

const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;
const LogoImg = tw.img`w-12 h-8`;
const LogoText = tw.h5`ml-2 text-xl font-black text-gray-500`;

const CompanyDescription = tw.p`mt-4 max-w-xs font-medium text-sm mx-auto md:mx-0 md:mr-4 `;

const SocialLinksContainer = tw.div`mt-4 `;
const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block p-2 rounded-full bg-gray-700 text-gray-100 hover:bg-gray-900 transition duration-300 mr-4`}
  svg {
    ${tw`w-4 h-4`}
  }
`;

export default function SimpleFiveColumn ({props,
  item = props})  {
    console.log(item);
  return (
    <Container>
      <Slide bottom>
      <FiveColumns>
        <WideColumn>
          <LogoContainer>
          <LogoImg src={`${config.apiurl}${item.logo.formats.small? item.logo.formats.small.url: item.logo.url}`} alt="img"/>
            <LogoText>{item.title}</LogoText>
          </LogoContainer>
          <CompanyDescription>
            {item.description}
          </CompanyDescription>
          <SocialLinksContainer>
            <SocialLink target="_blank" href={item.fb_link}>
              <FacebookIcon />
            </SocialLink>
            <SocialLink target="_blank" href={item.twitter_link}>
              <TwitterIcon />
            </SocialLink>
            <SocialLink target="_blank" href={item.linkedin_link}>
              <YoutubeIcon />
            </SocialLink>
          </SocialLinksContainer>
        </WideColumn>
        {item.footerData.map((n) => (
        <Column>
          <ColumnHeading>{n.title}</ColumnHeading>
          <LinkList>
          {n.links.map((n) => (
           <LinkListItem>
           <Link href="/">{n.link}</Link>
         </LinkListItem>
          ))}
          </LinkList>
        </Column>
        ))}
      </FiveColumns>
      </Slide>
    </Container>
  );
};
